import LeftArrowButton from "../small-components/LeftArrowButton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import grayheadphones from "../../assets/grayheadphones.svg";
import { SearchContext } from "../../store";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import speed from "../../assets/designSpeed.svg";
import calendar from "../../assets/calendar.svg";
import mail from "../../assets/grey-mail.svg";
import whitearrow from "../../assets/white-arrow.svg";

import Modal from "react-modal";

import "./styles.css";
import {
  bandwidths,
  fillAllFields,
  formatBandwidth,
  mobileRequestQuote,
  requestQuote,
} from "../../helpers";
import SentQuote from "../small-components/SentQuote";
import { serviceBuilding } from "../../store/slices/quotesSlice";
import Loader from "../small-components/Loader";
import { Mixpanel } from "../../helpers/mixpanel";

function GetQuote() {
  let history = useHistory();
  const dispatch = useDispatch();
  const { requestLoading } = useSelector((state) => state.quotes);

  const [searchMechanism, setSearchMechanism] = useContext(SearchContext);

  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(bandwidths[0]);
  const [email, setEmail] = useState("");
  const [term, setTerm] = useState(1);

  function openModal2() {
    setIsOpen2(true);
  }

  function afterOpenModal2() {
    // references are now sync'd and can be accessed.
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  const sendRequest = () => {
    Mixpanel.track("CLICKED GET EMAIL QUOTE");

    const quoteBody = {
      addresses: searchMechanism.addresses,
      service: searchMechanism.service,
      bandwidth: selectedQuote,
      email: email,
      term: term,
    };

    if (email && selectedQuote) {
      dispatch(serviceBuilding({ payload: quoteBody, cb: openModal2 }));
    } else {
      fillAllFields();
    }
  };

  useEffect(() => {
    // Clear previous quote amount local storage at get-quote
    var items = JSON.parse(localStorage.getItem("searchMechanism"));
    if (items) {
      items.quote = "";

      setSearchMechanism(items);

      localStorage.setItem("searchMechanism", JSON.stringify(items));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedQuote(bandwidths[0]);

    setSearchMechanism({
      ...searchMechanism,
      quote: bandwidths[0],
    });
    // eslint-disable-next-line
  }, [bandwidths]);

  useEffect(() => {
    Mixpanel.track("LANDED ON GET QUOTE SCREEN");
  }, []);

  return (
    <div className="lg:h-screen overflow-y-auto" id="body">
      <ToastContainer />
      <div className="px-6 sm:px-16 pt-10">
        <div className=" flex items-center justify-between">
          <button onClick={history.goBack}>
            <LeftArrowButton />
          </button>
          <a className="" href="tel:855-342-3737">
            <button className="lg:hidden cta2 border-gray text-lightgray bg-transparent">
              <img alt="arrow" src={grayheadphones} /> +1 855-342-3737
            </button>
          </a>
        </div>
        <div className="mt-10 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="mt-3 lg:mt-0 text-4xl font-medium order-2 lg:order-1 w-9/12">
            Last step to get Free Quote
          </h1>
          <span className="px-3 py-2 bg-fc text-lightgray text-sm rounded-full order-1 lg:order-2">
            Step 4 of 4
          </span>
        </div>
        <p className="mt-6 pb-4 border-b border-gray text-lightgray text-xs sm:text-sm">
          Choose Circuit size and term, and enter email address to get AI
          generated quote in minute or less!
        </p>
        {requestLoading ? (
          <Loader text="Sending Quote.." />
        ) : (
          <div className="mt-10">
            <div className="">
              <div className="mt-10 lg:w-10/12 xl:w-8/12">
                <div className="tooltip mt-8">
                  <label className="text-lightgray text-xs sm:text-sm">
                    <div className="inline-flex items-center space-x-2 text-sm bg-fc text-lightgray py-2 px-3 rounded-full">
                      <img alt="speed" className="h-4" src={speed} />
                      <p className="">What’s your desired internet speed?</p>
                      <i className="text-lightergray font-thin fa fa-info-circle"></i>
                    </div>
                  </label>
                  <div>
                    <small className="tooltiptext text-lightergray font-medium">
                      If you are uncertain, we suggest you choose 100MB, we can
                      also rerun this quote with another speed
                    </small>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  {bandwidths.map((bandwidth) => (
                    <div className="input-wrapper2" key={bandwidth}>
                      <input
                        type="radio"
                        name="bandwidth"
                        value={bandwidth}
                        checked={selectedQuote === bandwidth}
                        onChange={() => {
                          setSelectedQuote(bandwidth);
                          document
                            .querySelectorAll("#years")
                            .forEach((element) => (element.checked = false));

                          Mixpanel.track("SELECTED BANDWIDTH", {
                            action: `${formatBandwidth(bandwidth)}`,
                          });

                          setSearchMechanism({
                            ...searchMechanism,
                            quote: bandwidth,
                            quote_body: bandwidth,
                          });
                        }}
                      />
                      <div className="text">
                        <span>{formatBandwidth(bandwidth)}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="tooltip mt-8">
                  <label className="text-lightgray text-xs sm:text-sm">
                    <div className="inline-flex items-center space-x-2 text-sm bg-fc text-lightgray py-2 px-3 rounded-full">
                      <img alt="speed" className="h-4" src={calendar} />
                      <p className="">How many months for Service contract?</p>
                      <i className="text-lightergray font-thin fa fa-info-circle"></i>
                    </div>
                  </label>
                  <div>
                    <small className="tooltiptext text-lightergray font-medium">
                      Though we list 12 & 24mo terms, it may not generate a
                      quote that well, as our offering for MOST locations
                      require 36 and 60mo terms. If uncertain, choose 36, and
                      that is the best balance between savings and length
                    </small>
                  </div>
                </div>
                <div className="pb-10 flex flex-wrap">
                  {
                    <>
                      {["1", "2", "3", "5"].map((year) => (
                        <div className="input-wrapper2" key={year}>
                          <input
                            type="radio"
                            id="years"
                            name="years"
                            value={year}
                            checked={term === parseInt(year)}
                            onChange={(e) => {
                              Mixpanel.track("SELECTED CONTRACT DURATION", {
                                action: `${year} year(s)`,
                              });

                              setTerm(parseInt(e.currentTarget.value));
                            }}
                          />
                          <div className="text uppercase whitespace-nowrap">
                            <span>{year * 12 + " Months"}</span>
                          </div>
                        </div>
                      ))}
                    </>
                  }
                </div>
              </div>
            </div>

            <div>
              <div className="inline-flex items-center space-x-2 text-sm bg-fc text-lightgray py-2 px-3 rounded-full">
                <img alt="speed" className="h-4" src={mail} />
                <p className="">Email Address</p>
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  className="px-1 py-3 placeholder-lightergray text-sm mt-2 border-b border-orange w-full focus:outline-none"
                  placeholder="example@domain.com"
                  required={true}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
        {!requestLoading && (
          <div className="flex justify-end mt-10">
            <button
              className="cta2 mt-7 lg:mt-0 w-full sm:w-auto justify-center border-blue text-white bg-blue"
              onClick={sendRequest}
            >
              <img alt="arrow" src={whitearrow} /> GET EMAIL QUOTE
            </button>
          </div>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen2}
        onAfterOpen={afterOpenModal2}
        onRequestClose={closeModal2}
        style={window.screen.width <= 500 ? mobileRequestQuote : requestQuote}
        ariaHideApp={false}
      >
        <SentQuote
          searchMechanism={searchMechanism}
          close={closeModal2}
          email={email}
        />
      </Modal>
    </div>
  );
}

export default GetQuote;
