import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SearchContext } from "../../store";

import logo from "../../assets/logo.svg";
import headphones from "../../assets/headphones.svg";
import { formatBandwidth } from "../../helpers";
import { Mixpanel } from "../../helpers/mixpanel";

function LeftSidebar() {
  const [searchMechanism] = useContext(SearchContext);
  return (
    <div className="hidden lg:block lg:w-5/12 bg-banner bg-cover lg:h-screen py-10 px-24">
      <div>
        <Link to="/">
          <button onClick={() => Mixpanel.track("CLICKED GO BACK")}>
            <img src={logo} alt="logo" className="h-8" />
          </button>
        </Link>
      </div>
      <div className="mt-16 text-white">
        <p className="text-xl py-5 border-b border-white border-opacity-10">
          Your Info
        </p>
        <div className="py-6 border-b border-white border-opacity-10">
          <Link to="/app/pick-service">
            <button
              onClick={() => Mixpanel.track("CLICKED SELECT SERVICE TYPE")}
            >
              <p className="text-sm opacity-75">Service Type</p>
            </button>
          </Link>
          <p className="mt-2">{searchMechanism?.service}</p>
        </div>
        <div className="py-6 border-b border-white border-opacity-10">
          <Link to="/app/addresses">
            <button
              onClick={() => Mixpanel.track("CLICKED SERVICE TYPE - LOCATION")}
            >
              <p className="text-sm opacity-75">Location</p>
            </button>
          </Link>
          <div>
            {searchMechanism?.addresses.map((address, index) => (
              <p key={address.address}>
                {index + 1}. {address.address}
              </p>
            ))}
          </div>
        </div>
        <div className="py-6 border-b border-white border-opacity-10">
          <p className="text-sm opacity-75">Quote</p>
          <p className="mt-2">
            {searchMechanism?.quote
              ? searchMechanism?.quote === "string" &&
                searchMechanism?.quote.includes("/")
                ? searchMechanism?.quote
                : formatBandwidth(searchMechanism?.quote)
              : ""}
          </p>

          <p className="mt-2"></p>
        </div>
      </div>
      <div className="mt-32">
        <a href="tel:855-342-3737">
          <button
            className="cta2 text-white bg-transparent"
            onClick={() => Mixpanel.track("CLICKED CALL SUPPORT")}
          >
            <img alt="arrow" src={headphones} className="mr-3" /> +1
            855-342-3737
          </button>
        </a>
      </div>
    </div>
  );
}

export default LeftSidebar;
